$blue: #12375a;
$light-blue: #e1ebf7;

.loader-wrapper {
	position: fixed;
	inset: 0;
	background-color: rgb(0, 0, 0, 0.2);
	left: 50%;
	transform: translateX(-50%);
	width: min(900px, 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;

	.loader {
		display: inline-block;
		width: 80px;
		height: 80px;
		scale: 1.5;

		div {
			position: absolute;
			width: 6px;
			height: 6px;
			background: $blue;
			border-radius: 50%;
			animation: lds-default 1.2s linear infinite;

			&:nth-child(1) {
				animation-delay: 0s;
				top: 37px;
				left: 66px;
			}
			&:nth-child(2) {
				animation-delay: -0.1s;
				top: 22px;
				left: 62px;
			}
			&:nth-child(3) {
				animation-delay: -0.2s;
				top: 11px;
				left: 52px;
			}
			&:nth-child(4) {
				animation-delay: -0.3s;
				top: 7px;
				left: 37px;
			}
			&:nth-child(5) {
				animation-delay: -0.4s;
				top: 11px;
				left: 22px;
			}
			&:nth-child(6) {
				animation-delay: -0.5s;
				top: 22px;
				left: 11px;
			}
			&:nth-child(7) {
				animation-delay: -0.6s;
				top: 37px;
				left: 7px;
			}
			&:nth-child(8) {
				animation-delay: -0.7s;
				top: 52px;
				left: 11px;
			}
			&:nth-child(9) {
				animation-delay: -0.8s;
				top: 62px;
				left: 22px;
			}
			&:nth-child(10) {
				animation-delay: -0.9s;
				top: 66px;
				left: 37px;
			}
			&:nth-child(11) {
				animation-delay: -1s;
				top: 62px;
				left: 52px;
			}
			&:nth-child(12) {
				animation-delay: -1.1s;
				top: 52px;
				left: 62px;
			}
		}

		@keyframes lds-default {
			0%,
			20%,
			80%,
			100% {
				transform: scale(1);
			}
			50% {
				transform: scale(1.5);
			}
		}
	}
}

.popup-wrapper {
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100vw;
	height: 100vh;
	transition: all 0.8s ease;
	left: 200vw;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 10;
	cursor: pointer;

	&.show {
		left: 50%;
	}

	.popup {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: min(820px, 90%);
		height: 45vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #e1ebf7;
		border-radius: 50px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

		h2,
		h3 {
			color: $blue;
			font-size: clamp(40px, 5vw, 60px);
			margin: unset;
		}
		h3 {
			font-size: clamp(30px, 3.5vw, 40px);
		}
	}
}

.form-header {
	background-color: $blue;
	align-items: flex-end;

	@media only screen and (max-width: 550px) {
		flex-direction: column;
		align-items: center;
	}

	h1 {
		flex-grow: 1;
		color: #fff;
		margin: unset;
		padding-bottom: 5px;
		margin-right: min(20px, 4vw);

		@media only screen and (max-width: 550px) {
			margin-right: unset;
			text-align: center;
			margin-inline: 5px;
		}
	}
	img {
		object-fit: contain;

		&:last-child {
			padding-left: min(30px, 5vw);
		}
	}
}

.form-body {
	.company-use {
		background-color: $light-blue;
		padding: 0px 10px 10px 10px;
		margin-block: 30px min(100px, 11vw);

		.company-use-title {
			font-size: 12px;
			font-weight: 500;
			margin: unset;
			padding-block: 4px 8px;
		}

		form {
			gap: 8px;

			@media only screen and (max-width: 615px) {
				flex-direction: column;
				row-gap: 16px;
			}

			label {
				font-weight: 500;
				column-gap: 6px;
				font-size: 14px;

				@media only screen and (max-width: 400px) {
					flex-direction: column;
					align-items: flex-start;
					row-gap: 12px;
				}

				input {
					background-color: transparent;
					border: unset;
					outline: none;
					border-bottom: 1px black solid;
					width: 153px;

					@media only screen and (max-width: 615px) {
						flex-grow: 1;
					}
					@media only screen and (max-width: 400px) {
						width: 100%;
					}
				}
			}
		}
	}

	.personal-details {
		margin-bottom: min(50px, 7vw);

		.title {
			font-weight: 500;
			margin-bottom: 20px;
		}

		form {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			column-gap: 15px;
			row-gap: 20px;

			> label {
				display: flex;
				flex-direction: column;

				input {
					margin-top: 20px;
					border: unset;
					border-bottom: 1px black solid;
					font-size: 16px;
					outline: none;
					line-height: 1;
				}

				&:nth-child(1) {
					grid-area: 1 / 1 / 2 / 5;

					@media only screen and (max-width: 768px) {
						grid-area: 1/1/2/5;
					}
					@media only screen and (max-width: 500px) {
						grid-area: 1/1/2/13;
					}
				}
				&:nth-child(2) {
					grid-area: 1 / 5 / 2 / 9;

					@media only screen and (max-width: 768px) {
						grid-area: 1/5/2/9;
					}
					@media only screen and (max-width: 500px) {
						grid-area: 2/1/3/13;
					}
				}
				&:nth-child(3) {
					grid-area: 1 / 9 / 2 / 13;

					@media only screen and (max-width: 768px) {
						grid-area: 1/9/2/13;
					}
					@media only screen and (max-width: 500px) {
						grid-area: 3/1/4/13;
					}
				}
				&:nth-child(5) {
					grid-area: 2 / 3 / 3 / 5;

					@media only screen and (max-width: 768px) {
						grid-area: 2/7/3/13;
						max-width: 190px;
					}
					@media only screen and (max-width: 500px) {
						grid-area: 5/1/6/13;
						max-width: unset;
					}

					input {
						text-align: center;

						&::-webkit-inner-spin-button,
						&::-webkit-calendar-picker-indicator {
							display: none;
							-webkit-appearance: none;
						}
					}
				}
				&:nth-child(6) {
					grid-area: 2 / 5 / 3 / 11;

					@media only screen and (max-width: 768px) {
						grid-area: 3/1/3/8;
					}
					@media only screen and (max-width: 500px) {
						grid-area: 6/1/7/13;
					}
				}
				&:nth-child(7) {
					grid-area: 2 / 11 / 3 / 13;

					@media only screen and (max-width: 768px) {
						grid-area: 3/8/4/13;
					}
					@media only screen and (max-width: 500px) {
						grid-area: 7/1/8/13;
					}
				}
				&:nth-child(8) {
					grid-area: 3 / 1 / 4 / 9;

					@media only screen and (max-width: 768px) {
						grid-area: 4/1/4/9;
					}
					@media only screen and (max-width: 500px) {
						grid-area: 8/1/9/13;
					}
				}
				&:nth-child(9) {
					grid-area: 3 / 9 / 4 / 13;

					@media only screen and (max-width: 768px) {
						grid-area: 4/9/4/13;
					}
					@media only screen and (max-width: 500px) {
						grid-area: 9/1/10/13;
					}
				}
				&:nth-child(10) {
					grid-area: 4 / 1 / 5 / 7;

					@media only screen and (max-width: 768px) {
						grid-area: 5/1/5/7;
					}
					@media only screen and (max-width: 500px) {
						grid-area: 10/1/11/13;
					}
				}
			}

			.gender-wrapper {
				grid-area: 2 / 1 / 3 / 3;

				@media only screen and (max-width: 768px) {
					grid-area: 2/1/3/6;
				}
				@media only screen and (max-width: 500px) {
					grid-area: 4/1/5/13;
				}
			}
			.car-wrapper {
				grid-area: 4 / 7 / 5 / 10;

				@media only screen and (max-width: 768px) {
					grid-area: 5/7/5/9;
				}
				@media only screen and (max-width: 500px) {
					grid-area: 11/1/12/13;
				}
			}
			.license-wrapper {
				grid-area: 4 / 10 / 5 / 13;

				@media only screen and (max-width: 768px) {
					grid-area: 5/9/5/13;
				}
				@media only screen and (max-width: 500px) {
					grid-area: 12/1/13/13;
				}
			}

			.gender-wrapper,
			.car-wrapper,
			.license-wrapper {
				text-align: center;

				p {
					margin: unset;
					padding-bottom: 20px;
				}

				> div {
					display: flex;
					align-items: center;
					justify-content: center;
					column-gap: 8px;

					label {
						display: flex;
						line-height: 1.3;
					}
				}
			}
		}
	}

	.education,
	.courses {
		.title {
			font-weight: 500;
			margin-bottom: 20px;
		}

		.education-table {
			width: 99.7%;
			margin-bottom: 20px;

			@media only screen and (max-width: 768px) {
				flex-direction: column;
				align-items: center;
			}

			.column {
				&:first-child {
					font-weight: 700;
				}

				&:last-child {
					div {
						border-left: 1px solid black;
					}
				}
				div {
					width: 100%;
					height: 30px;
					line-height: 1.6;
					border-top: 1px solid black;
					border-right: 1px solid black;

					&:last-child {
						border-bottom: 1px solid black;
					}

					&:first-child {
						background-color: $light-blue;
						font-weight: 700;
					}

					input {
						width: 95%;
						height: 100%;
						outline: none;
						border: unset;
						font-size: 16px;
						// padding-right: 4px;
					}
				}

				&:nth-child(1) {
					width: 22%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(2) {
					width: 14%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(3) {
					width: 18%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(4) {
					width: 46%;

					@media only screen and (max-width: 768px) {
						width: 100%;
					}
				}
			}
		}
	}

	.courses {
		margin-block: min(50px, 7vw);

		.courses-table {
			width: 99.7%;
			margin-bottom: 20px;

			@media only screen and (max-width: 768px) {
				flex-direction: column;
				align-items: center;
			}

			.column {
				&:last-child {
					div {
						border-left: 1px solid black;
					}
				}
				div {
					width: 100%;
					height: 30px;
					line-height: 1.6;
					border-top: 1px solid black;
					border-right: 1px solid black;

					&:last-child {
						border-bottom: 1px solid black;
					}

					&:first-child {
						background-color: $light-blue;
						font-weight: 700;
					}

					input {
						width: 95%;
						height: 100%;
						outline: none;
						border: unset;
						font-size: 16px;
						// padding-right: 4px;
					}
				}

				&:nth-child(1) {
					width: 15%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(2) {
					width: 15%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(3) {
					width: 20%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(4) {
					width: 50%;

					@media only screen and (max-width: 768px) {
						width: 100%;
					}
				}
			}
		}
	}

	.questions {
		row-gap: 20px;

		.question1,
		.question2,
		.question3 {
			column-gap: 16px;

			@media only screen and (max-width: 805px) {
				display: grid;
				grid-template-columns: repeat(1, 0.3fr);
				grid-template-rows: repeat(3, 1fr);
				row-gap: 10px;
			}
			@media only screen and (max-width: 720px) {
				grid-template-columns: unset;
				grid-template-rows: unset;
			}

			.title {
				font-weight: 500;

				@media only screen and (max-width: 805px) {
					grid-area: 1 / 1 / 2 / 3;
				}
				@media only screen and (max-width: 720px) {
					grid-area: unset;
				}
			}

			.checkboxes {
				column-gap: 8px;

				@media only screen and (max-width: 805px) {
					grid-area: 2 / 1 / 3 / 2;
				}
				@media only screen and (max-width: 720px) {
					grid-area: unset;
				}
			}

			.if-yes {
				column-gap: 16px;
				flex-grow: 1;

				@media only screen and (max-width: 805px) {
					grid-area: 2 / 2 / 3 / 3;
				}
				@media only screen and (max-width: 720px) {
					grid-area: unset;
				}

				input {
					border: unset;
					border-bottom: 1px solid black;
					outline: none;
					font-size: 16px;
					flex-grow: 1;
				}
			}
		}

		.question2 {
			.title {
				white-space: nowrap;

				@media only screen and (max-width: 400px) {
					white-space: unset;
				}
			}

			.if-yes {
				@media only screen and (max-width: 540px) {
					flex-direction: column;
					row-gap: 10px;
				}

				.relation {
					column-gap: 4px;

					@media only screen and (max-width: 540px) {
						width: 100%;
					}

					input {
						width: 48%;
					}
				}

				.with-who {
					column-gap: 4px;

					@media only screen and (max-width: 805px) {
						flex-grow: 1;
					}
					@media only screen and (max-width: 540px) {
						width: 100%;
					}

					input {
						width: 70%;
					}
				}
			}
		}

		.question3 {
			.title {
				white-space: nowrap;
			}
			.if-yes {
				@media only screen and (max-width: 540px) {
					flex-direction: column;
					row-gap: 10px;
				}

				.relation {
					column-gap: 4px;

					@media only screen and (max-width: 540px) {
						width: 100%;
					}

					input {
						width: 57%;
					}
				}

				.which-role {
					column-gap: 4px;

					@media only screen and (max-width: 805px) {
						flex-grow: 1;
					}
					@media only screen and (max-width: 540px) {
						width: 100%;
					}

					input {
						width: 56%;
					}
				}
			}
		}
	}

	.prev-places {
		margin-block: min(50px, 7vw);

		.title {
			column-gap: 4px;
			align-items: flex-end;
			margin-bottom: 20px;

			h4 {
				margin: unset;
			}

			span {
				font-size: 12px;
			}
		}

		.prev-places-table {
			width: 99.7%;

			@media only screen and (max-width: 768px) {
				flex-direction: column;
				align-items: center;
			}

			.column {
				&:last-child {
					div {
						border-left: 1px solid black;
					}
				}
				> div {
					width: 100%;
					height: 30px;
					line-height: 1.6;
					border-top: 1px solid black;
					border-right: 1px solid black;

					&:last-child {
						border-bottom: 1px solid black;
					}

					&:first-child {
						background-color: $light-blue;
						font-weight: 700;
						height: 43px;
						line-height: 2.5;
					}

					input {
						width: 95%;
						height: 100%;
						outline: none;
						border: unset;
						font-size: 16px;
						// padding-right: 4px;
					}
				}

				&:nth-child(1) {
					width: 22%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(2) {
					width: 14%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(3) {
					width: 18%;

					div {
						line-height: 1.3;

						&:first-child {
							column-gap: 60px;
						}
						&:not(:first-child) {
							input {
								&:first-child {
									border-left: 1px black solid;
								}
							}
						}
					}

					@media only screen and (max-width: 768px) {
						width: 100%;
						> div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(4) {
					width: 23%;

					@media only screen and (max-width: 768px) {
						width: 100%;
						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(5) {
					width: 23%;

					@media only screen and (max-width: 768px) {
						width: 100%;
					}
				}
			}
		}
	}

	.recommendation {
		margin-bottom: min(50px, 7vw);

		.title {
			column-gap: 4px;
			align-items: flex-end;
			margin-bottom: 20px;

			p {
				font-size: 12px;
			}
		}

		.recommendation-table {
			width: 99.7%;

			@media only screen and (max-width: 768px) {
				flex-direction: column;
				align-items: center;
			}

			.column {
				&:last-child {
					div {
						border-left: 1px solid black;
					}
				}
				div {
					width: 100%;
					height: 30px;
					line-height: 1.6;
					border-top: 1px solid black;
					border-right: 1px solid black;

					&:last-child {
						border-bottom: 1px solid black;
					}

					&:first-child {
						background-color: $light-blue;
						font-weight: 700;
					}

					input {
						width: 95%;
						height: 100%;
						outline: none;
						border: unset;
						font-size: 16px;
						// padding-right: 4px;
					}
				}

				&:nth-child(1) {
					width: 24%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(2) {
					width: 18%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(3) {
					width: 29%;

					@media only screen and (max-width: 768px) {
						width: 100%;

						div {
							border-left: 1px black solid;
						}
					}
				}
				&:nth-child(4) {
					width: 29%;

					@media only screen and (max-width: 768px) {
						width: 100%;
					}
				}
			}
		}
	}

	.languages-and-applications {
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			justify-content: center;
			row-gap: 20px;
		}

		.languages {
			width: 64%;

			@media only screen and (max-width: 768px) {
				width: 100%;
			}

			.title {
				column-gap: 4px;
				align-items: flex-end;
				margin-bottom: 20px;

				p {
					font-size: 12px;
				}
			}

			.languages-table {
				width: 99.7%;

				@media only screen and (max-width: 768px) {
					flex-direction: column;
					align-items: center;
				}

				.column {
					&:last-child {
						div {
							border-left: 1px solid black;
						}
					}
					div {
						width: 100%;
						height: 31.5px;
						line-height: 1.6;
						border-top: 1px solid black;
						border-right: 1px solid black;

						&:last-child {
							border-bottom: 1px solid black;
						}

						&:first-child {
							background-color: $light-blue;
							font-weight: 700;
						}

						input {
							width: 95%;
							height: 100%;
							outline: none;
							border: unset;
							font-size: 16px;
							// padding-right: 4px;
						}
					}

					&:nth-child(1) {
						width: 20%;

						@media only screen and (max-width: 768px) {
							width: 100%;

							div {
								border-left: 1px black solid;
							}
						}
					}
					&:nth-child(2) {
						width: 20%;

						@media only screen and (max-width: 768px) {
							width: 100%;

							div {
								border-left: 1px black solid;
							}
						}
					}
					&:nth-child(3) {
						width: 20%;

						@media only screen and (max-width: 768px) {
							width: 100%;

							div {
								border-left: 1px black solid;
							}
						}
					}
					&:nth-child(4) {
						width: 20%;

						@media only screen and (max-width: 768px) {
							width: 100%;

							div {
								border-left: 1px black solid;
							}
						}
					}
					&:nth-child(5) {
						width: 20%;

						@media only screen and (max-width: 768px) {
							width: 100%;
						}
					}
				}
			}
		}

		.applications {
			width: 33%;

			@media only screen and (max-width: 768px) {
				width: 100%;
			}

			.title {
				column-gap: 4px;
				align-items: flex-end;
				margin-bottom: 20px;

				p {
					font-size: 12px;
				}
			}

			table {
				width: 100%;
				border-spacing: unset;
				border: 1px black solid;

				thead {
					tr {
						background-color: $light-blue;
						height: 30px;

						th {
							border-bottom: 1px black solid;

							&:not(:last-child) {
								border-left: 1px black solid;
							}
						}
					}
				}

				tbody {
					tr {
						&:not(:last-child) {
							td {
								border-bottom: 1px black solid;
							}
						}
						td {
							position: relative;
							height: 30px;
							width: 127px;

							&:not(:last-child) {
								border-left: 1px black solid;
							}
							input {
								position: absolute;
								inset: 0;
								width: min(129px, 95%);
								font-size: 16px;
								border: unset;
								outline: none;
								padding-right: 5px;

								@media only screen and (max-width: 768px) {
									width: unset;
								}
							}
						}
					}
				}
			}
		}
	}

	.deficiencies {
		column-gap: 8px;
		align-items: flex-end;
		padding-block: 30px 40px;

		textarea {
			outline: none;
			resize: none;
			border: unset;
			font-size: 16px;
			width: 100%;

			line-height: 4ch;
			background-image: linear-gradient(
				transparent,
				transparent calc(4ch - 1px),
				#000000 0px
			);
			background-size: 100% 4ch;

			&.pdf {
				background-image: unset;
			}
		}
	}

	.signature {
		padding-bottom: 50px;
		.title {
			padding-bottom: 50px;
		}

		.signature-and-date {
			column-gap: 40px;

			@media only screen and (max-width: 640px) {
				flex-direction: column-reverse;
				align-items: center;
				row-gap: 40px;
			}

			label {
				row-gap: 8px;
				font-weight: 700;

				input {
					outline: none;
					border: unset;
					border-bottom: 1px solid black;
					font-size: 16px;
					width: 195px;
					line-height: 1;

					&[type='date'] {
						text-align: center;

						&::-webkit-inner-spin-button,
						&::-webkit-calendar-picker-indicator {
							display: none;
							-webkit-appearance: none;
						}
					}
				}
			}

			.signature-wrapper {
				position: relative;
				row-gap: 8px;

				.signature-canvas {
					opacity: 0;
					visibility: hidden;
					max-height: 0;
					max-width: 0;
					transition: all 0.3s ease;

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: #fff;
					padding: min(50px, 15vw) min(50px, 7vw);
					box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
					border-radius: 20px;
					overflow: hidden;

					canvas {
						width: max(20.833vw, 260px);
					}

					&.show {
						opacity: 1;
						visibility: visible;
						max-height: 200px;
						max-width: 400px;
					}

					&.finish {
						background: unset;
						border: unset;
						padding: unset;
						box-shadow: unset;
						transform: unset;
						position: static;

						canvas {
							width: 200px;
							border: unset !important;
						}
					}

					.buttons {
						width: 100%;
						column-gap: 16px;
						margin-top: 8px;
					}
				}

				button {
					font-size: 16px;
					background-color: transparent;
					border: unset;
					border: 1px $blue solid;
					border-radius: 5px;
					cursor: pointer;
					outline: none;

					&.hide {
						display: none;
					}
				}
				span {
					font-weight: 700;
				}
			}
		}
	}

	.comments {
		padding-bottom: 40px;

		textarea {
			outline: none;
			resize: none;
			border: unset;
			font-size: 16px;
			width: 100%;

			line-height: 4ch;
			background-image: linear-gradient(
				transparent,
				transparent calc(4ch - 1px),
				#000000 0px
			);
			background-size: 100% 4ch;

			&.pdf {
				background-image: unset;
			}
		}
	}

	.send-button {
		padding-bottom: 70px;
		text-align: center;

		&.pdf {
			display: none;
		}

		input {
			border: unset;
			background-color: $blue;
			color: white;
			font-size: 24px;
			border-radius: 50px;
			padding: 12px 30px;
			cursor: pointer;
		}
	}
}

.form-footer {
	background-color: $light-blue;

	.image {
		transform: translateY(-52%);
	}
}
