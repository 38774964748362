.absolute {
	position: absolute;
}
.relative {
	position: relative;
}

.text-center {
	text-align: center;
}

.grid {
	display: grid;
	height: 100%;
	width: 100%;
}

.flex {
	display: flex;
}
.flex-reverse {
	display: flex;
	flex-direction: row-reverse;
}
.wrap {
	flex-wrap: wrap;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.justify-between {
	justify-content: space-between;
}
.justify-around {
	justify-content: space-around;
}
.justify-center {
	justify-content: center;
}
.justify-evenly {
	justify-content: space-evenly;
}
.align-center {
	align-items: center;
}
.align-end {
	align-items: flex-end;
}
.absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

p,
h4 {
	margin: unset;
}

input {
	padding: unset;
	direction: rtl;
	text-align: right;
}

.App {
	direction: rtl;
	color: #424044;
	max-width: 900px;
	margin-inline: auto;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	min-height: 100vh;
}

.container {
	margin-inline: 23px;
}
